import React, {useEffect, useState} from "react";
import {axiosClient} from '../api/httpClient';
import {
    Button,
    Card,
    CardPanel,
    CardTitle,
    Col,
    Container,
    Icon,
    Modal,
    ProgressBar,
    Row,
    Select,
    Table,
    TextInput
} from "react-materialize";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import * as actions from '../actions';
import * as _ from "lodash";
import {Link} from "react-router-dom";
import {errorToast, generateRandomPassword, getUserOptions, infoToast, isTrue, successToast, toISODate, filterCustomerList, exportTableToCSV, isMobile} from "../api/Util";
import ConfirmationDialog from "./ConfirmationDialog";
import {ADMIN, CUSTOM, DOUBLE, QA_USER, SINGLE, UNLIMITED, WEEKLY, ACTIVE, INACTIVEFOR14DAYS, INACTIVEFOR30DAYS, VIDCHOPS_WEEKLY, VIDCHOPS_PRO} from "../api/Constants";
import TopHeader from "./TopHeader";
import { DateRangePicker } from "react-date-range";
import { fetchCustomerDetails, getMonthCardRequestOfActiveCustomer } from "../services/customerService";
import { Input, Spin } from "antd";
import { activityOptions, referralOptions, subscriptionOptions } from "../constant/DropdownData";
import CustomDropdown from "./common/CustomDropdown/DropDown";
import CustomDatePicker from "./common/DatePicker";

const INITIAL_FILTER_STATE = {
    subscriptionType : "",
    referral : "",
    customerActivity: "",
    startDate : "",
    endDate : "",
};

function populateCustomers(props, confirmationOpen, setConfirmationOpen, onConfirmDelete, setOnConfirmDelete, filterData, filterActivityCustomers) {
    if(!props?.users?.users && !props.users.loadingUsers ) {
        props.fetchAllUsers();
    }

    if (!props?.users?.customers && !props.users.loadingCustomers) {
        props.fetchAllCustomers();
        return (
            <ProgressBar/>
        );
    }
    if (_.isEmpty(props?.users?.customers)) {
        return (
            <CardPanel style={{padding: "12px", textAlign: "center"}}>
                <span style={{fontSize: "1.1em"}}>No customers found</span>
            </CardPanel>
        );
    }

    function onClickDelete(e, user) {
        setOnConfirmDelete((event) => () => deleteUser(event, user));
        setConfirmationOpen(true);
    }

    function onClickDeactivate(e, user) {
        axiosClient.post(`/api/user/${user.client_id}/toggleUserSubscription`, {
            client_id: user.client_id, is_active_subscription: user.is_active_subscription ? 0 : 1
        }).then(({data}) => {
            successToast(data.status || "Status updated");
            props.fetchAllCustomers();
        }).catch(err => {
           errorToast("Something went wrong");
           console.error(err);
        });
    }

    function renewSubscription(clientId) {
        if (window.confirm('Are you sure you want to renew subscription?')) {
            axiosClient.post(`/api/user/${clientId}/renewSubscription`)
                .then(({data}) => {
                    if(data.success) {
                        successToast("Subscription renewed");
                    } else {
                        errorToast("Couldn't renew subscription, please try again");
                    }
                }).catch(err => {
                    console.error(err);
                    errorToast('Something went wrong when renewing subscription');
            });
        }
    }

    let showFields = props.auth.loggedInUser.client_type === ADMIN ? true : false;

    async function deleteUser(e, user) {
        try {
            let response = await axiosClient.delete('/api/user/' + user.client_id);
            successToast(response.data.message);
            props.removeCustomer(user);
        } catch (err) {
            errorToast(err.data.message || "Unable to delete customer");
        }
        setConfirmationOpen(false);
    }

    var customersList = filterData.customerActivity ? filterActivityCustomers : filterCustomerList(props?.users?.customers, filterData);

    return (
        <Container>
            <ConfirmationDialog
                onNegative={() => setConfirmationOpen(false)}
                onPositive={onConfirmDelete}
                confirmationHeader="Caution"
                confirmationDialogId="customerDeleteConfirmationDialog"
                confirmation={confirmationOpen}
                confirmationText="Are you sure you want to delete customer?"
            />
                <Row className="users-list-container">
            {
                _.map(
                    _.filter(customersList, (user) => {
                        if (props.users.searchedUser) 
                            return user?.fullname?.toLowerCase().includes(props.users.searchedUser.toLowerCase());
                        else
                            return user;
                    }),
                    (user) => {
                    return (
                        <Col className="customer-col" s={12} m={4} l={4} key={`customer-${user.client_id}`}>
                            <Card
                                header={<CardTitle className="user-card-title" image="">
                                    <Link to={`/customer/${user.client_id}/profile`}>{user.fullname}</Link>
                                </CardTitle>}
                                actions={[
                                    <Button key={`delete-${user.client_id}`} onClick={e => onClickDelete(e, user)} disabled = {!showFields}
                                            className="btn-danger" style={{width: "50%"}}>
                                        Delete <Icon right>delete_forever</Icon>
                                    </Button>,
                                    <Button key={`inactive-${user.client_id}`} onClick={e => onClickDeactivate(e, user)} disabled = {!showFields}
                                            className={user.is_active_subscription ? "btn-danger" : "btn-primary"}>
                                        {user.is_active_subscription ? "Deactivate" : "Activate"}<Icon right>check</Icon>
                                    </Button>
                                ]}>
                                <Container style={{marginTop: "10px", minHeight: "280px"}}>
                                    <Row>
                                        <Col s={12}>
                                            <FontAwesomeIcon icon="user-tag"/>&nbsp;{(user.subscription_type || "")}
                                            {user.subscription_type === CUSTOM && user.custom_subscription ? `: ${user.custom_subscription}` : null}
                                            &nbsp;&nbsp;
                                            {
                                                user.subscription_type === SINGLE ?
                                                showFields && <Button tooltip="Renew subscription" icon={<Icon>refresh</Icon>}
                                                            onClick={() => renewSubscription(user.client_id)} />
                                                    : null
                                            }
                                        </Col>
                                    </Row>
                                    {
                                        isTrue(user.has_subtitles) &&
                                            <Row>
                                                <Col s={12}>
                                                    <FontAwesomeIcon icon="plus-square"/>&nbsp;Has Subtitles
                                                </Col>
                                            </Row>
                                    }
                                    {
                                        isTrue(user.has_youtube_helper) &&
                                            <Row>
                                                <Col s={12}>
                                                    <FontAwesomeIcon icon="plus-square"/>&nbsp;Has Youtube Helper
                                                </Col>
                                            </Row>
                                    }
                                    <Row>
                                        <Col s={12}>
                                            <FontAwesomeIcon icon="at"/>&nbsp;<a
                                            href={`mailto:${user.useremail}`}>{user.useremail}</a>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col s={12}>
                                            <span>
                                                <FontAwesomeIcon icon="at"/>&nbsp;Alternate:
                                                {
                                                    user.secondary_email &&
                                                    (<a target="_blank" rel="noopener noreferrer" href={`mailto:${user.secondary_email.split(",")[0]}`}>{user.secondary_email.substring(0, 40) + '...' || 'NA'}</a>)
                                                }
                                            </span>
                                        </Col>
                                    </Row>
                                    {
                                        user?.assigned?.map(assignment => (<Row key={"assignment-" + assignment.client_id}>
                                            <Col s={12}>
                                                <FontAwesomeIcon icon="user-tie"/>&nbsp;Assigned: <Link
                                                to={`/user/${assignment.client_id}/profile`}>{assignment.name} ({assignment.client_type})</Link>
                                            </Col>
                                        </Row>))
                                    }
                                </Container>
                            </Card>
                        </Col>
                    );
                })
            }
                </Row>
        </Container>
    );
}

export default connect(mapStateToProps, actions)((props) => {
    let [firstName, setFirstName] = useState("");
    let [lastName, setLastName] = useState("");
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    let [type, setType] = useState(SINGLE);
    let [customType, setCustomType] = useState('');
    let [qa, setQa] = useState("0");
    let [modalOpen, setModalOpen] = useState(false);
    let [addDisabled, setAddDisabled] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [onConfirmDelete, setOnConfirmDelete] = useState(() => {});
    const [filterCsvData, setFilterCsvData] = useState([]);
    const [filterActivityCustomers, setFilterActivityCustomers] = useState([]);
    const [allowedCards, setAllowedCards] = useState(0);
    const [requestedCards, setRequestedCards] = useState(0);
    const [activeCount, setActiveCount] = useState(0);
    const [inactiveCount, setInactiveCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [filterData, setfilterData] = useState(props?.filter?.filterData ? props.filter.filterData : INITIAL_FILTER_STATE);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    useEffect(() => {
        let filterList = [];
        if (filterData && filterData.customerActivity) {
            // eslint-disable-next-line array-callback-return
            props.users.customers.filter(customer => {
                if (customer.last_login_time !== null) {
                    var diffInTime = new Date().getTime() - new Date(customer.last_login_time).getTime();
                    var diffInDays = diffInTime / (1000 * 3600 * 24);
                    if (filterData.customerActivity === INACTIVEFOR30DAYS && diffInDays > 30) {
                        filterList.push(customer);
                    } else if (filterData.customerActivity === INACTIVEFOR14DAYS && diffInDays < 30 && diffInDays > 14) {
                        filterList.push(customer);
                    } else if (filterData.customerActivity === ACTIVE && diffInDays < 14) {
                        filterList.push(customer);
                    }
                }
            })
        };
        setFilterActivityCustomers(filterList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData.customerActivity, props.users.customers]);

    const getActiveCustomerIds = () => {
        return filterActivityCustomers.length > 0 ? filterActivityCustomers : props.users.customers
            .filter(customer => customer.is_active_subscription && (!filterData?.subscriptionType || customer.subscription_type === filterData.subscriptionType))
            .map(customer => customer.client_id);
    };
    const activeFilterCount = () => {
        let count = 0;
        if (filterData?.startDate && filterData?.endDate) count++;
        if (filterData.subscriptionType) count++;
        if (filterData.referral) count++;
        if (filterData.customerActivity) count++;
        return count;
    };

    useEffect(() => {
        getMonthCardRequest();
        updateActiveInactiveCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.users.customers, filterData.subscriptionType, filterActivityCustomers.length > 0]);
    
    const getMonthCardRequest = async () => {
        setLoading(true);
        if (props?.users?.customers) {
            const activeCustomerIds = getActiveCustomerIds();
            const res = await getMonthCardRequestOfActiveCustomer(filterData, activeCustomerIds);
            if (res.status === true) {
                setAllowedCards(res?.data?.totalAllowedVideoCount);
                setRequestedCards(res?.data?.oneMonthCreatedVideo);
                setLoading(false);
            }
        }
    };
    
    const updateActiveInactiveCustomers = () => {
        if (props?.users?.customers) {
            const activeCustomerIds = getActiveCustomerIds();
            const active = activeCustomerIds.length;
            const inactive = props.users.customers.length - active;
            setActiveCount(active);
            setInactiveCount(inactive);
        }
    };
    
    async function addCustomer(e) {
        setAddDisabled(true);
        try {
            e.preventDefault();
            infoToast("Creating new Customer");
            let response = await axiosClient.post('/api/user',
                {
                    firstname: firstName, lastname: lastName, email, password, custom_subscription: customType,
                    subscription_type: type, assign_qa: qa === "0" ? null : [Number(qa)]
                });
            successToast(response.data.message);
            setAddDisabled(false);
            if (response.data && !_.isEmpty(response.data.entity)) {
                props.addCustomer(response.data.entity);
            }
            setModalOpen(false);
        } catch (err) {
            errorToast(err.response.data.error_message || 'Something went wrong: ' + err.message);
            setAddDisabled(false);
        }
    }

    function setRandomPassword() {
        setPassword(generateRandomPassword);
    }

    function resetFields() {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setType(SINGLE);
        setQa("0");
    }

    const handleSubscriptionChange = (value) => {
        setfilterData(prev => ({ ...prev, subscriptionType: value }));
    };

    const handleReferralChange = (value) => {
        setfilterData(prev => ({ ...prev, referral: value }));
    };

    const handleActivityChange = (value) => {
        setfilterData(prev => ({ ...prev, customerActivity: value }));
    };

    const exportCustomers = async () => {
        infoToast("Loading")
        const res = await fetchCustomerDetails(filterData);
        if (res.status) {
            setFilterCsvData(res.data)
            exportTableToCSV('customers-table', `Customers.csv`);
        } else {
            errorToast("Something went wrong");
        }
    }

    return (
        <Container>
            <div className="aside-right-top-bar all-customer-topbar">
                <div className="top-bar-left">
                    <div className="top_bar">
                        <h3>Customers</h3>
                    </div>
                </div>
                <div className="top-bar-right">
                    <TopHeader/>
                </div>
            </div>
            <div className="aside-top-filters filter-customer-top">
            <Row className="users-top-btn customer-top-header">
  

                <Col className="inputSearchUser mb-0">
                    <Input
                        placeholder="Search by name"
                        allowClear 
                        style={{height:"49px" }}
                        className="searchedUser"
                        value={props.users.searchedUser}
                        onChange={(e) => props.searchUser(e.target.value)}
                    />
                </Col> 
                <Col className="mb-0 d-inline-flex align-items-center">
                    <CardPanel style={{padding: "12px", textAlign: "center"}} className="standard-btn">
                        <FontAwesomeIcon icon="users"/>&nbsp;&nbsp;
                        <span className="text-accent-1" style={{fontSize: "1.1em"}}>Active: {activeCount}</span>
                    </CardPanel>
                </Col>
                <Col className="mb-0 d-inline-flex align-items-center">
                    <CardPanel style={{padding: "12px", textAlign: "center"}} className="standard-btn">
                        <FontAwesomeIcon icon="users"/>&nbsp;&nbsp;
                        <span className="text-darken-1" style={{fontSize: "1.1em"}}>Inactive: {inactiveCount}</span>
                    </CardPanel>
                </Col>
                <Col className="mb-0 d-inline-flex align-items-center">
                    <Modal
                        actions={[
                            <Button flat modal="close" node="button" className="btn-danger" large>Close</Button>,
                            <Button modal="close" onClick={e => addCustomer(e)} disabled={addDisabled} node="button"
                                    waves="green" className="btn-primary" large>Add</Button>
                        ]}
                        trigger={(
                            <CardPanel style={{padding: "12px", textAlign: "center", cursor: "pointer"}}
                                       className="color-green-imp standard-btn" >
                                <FontAwesomeIcon icon="plus" inverse/>&nbsp;&nbsp;
                                <span className="text-primary" style={{fontSize: "1.1em", color: '#fff'}}>{!isMobile && 'Add '}Customer</span>
                            </CardPanel>
                        )}
                        header="Add New Customer"
                        id="addCustomerModal"
                        open={modalOpen}
                        style={{height: '60%'}}
                        options={{
                            dismissible: true,
                            endingTop: '10%',
                            opacity: 0.5,
                            preventScrolling: true,
                            onOpenStart: resetFields,
                            onCloseEnd: () => setModalOpen(false)
                        }}
                    >
                        <Container>
                            <Row>
                                <TextInput s={12} m={6} id="txtFirstName" label="First Name" icon="person"
                                           value={firstName} onChange={e => setFirstName(e.target.value)}/>
                                <TextInput s={12} m={6} id="txtLastName" label="Last Name" icon="person"
                                           value={lastName} onChange={e => setLastName(e.target.value)}/>
                            </Row>
                            <Row>
                                <TextInput s={12} m={6} id="txtEmail" label="Email" email validate icon="email"
                                           value={email} onChange={e => setEmail(e.target.value)}/>
                                <TextInput s={12} m={6} icon={<Button flat onClick={setRandomPassword}><Icon>lock</Icon></Button>}
                                           id="txtPassword" label="Password"
                                           value={password} onChange={e => setPassword(e.target.value)}/>
                            </Row>
                            <Row>
                                <Select s={12} m={6}
                                        icon={<Icon>subscriptions</Icon>}
                                        id="subscriptionType"
                                        label="Customer Type"
                                        value={type}
                                        onChange={e => setType(e.target.value)}>
                                    <option value={SINGLE}>
                                        {SINGLE}
                                    </option>
                                    <option value={WEEKLY}>
                                        {WEEKLY}
                                    </option>
                                    <option value={UNLIMITED}>
                                        {UNLIMITED}
                                    </option>
                                    <option value={DOUBLE}>
                                        {DOUBLE}
                                    </option>
                                    <option value={CUSTOM}>
                                        {CUSTOM}
                                    </option>
                                    <option value={VIDCHOPS_WEEKLY}>
                                        {VIDCHOPS_WEEKLY}
                                    </option>
                                    <option value={VIDCHOPS_PRO}>
                                        {VIDCHOPS_PRO}
                                    </option>
                                </Select>
                                <Select s={12} m={6}
                                        icon={<Icon>supervisor_account</Icon>}
                                        id="assignQa"
                                        label="Assign QA"
                                        value={qa}
                                        onChange={e => setQa(e.target.value)}>
                                    <option value="0">
                                        None
                                    </option>
                                    {getUserOptions(props.users.users, QA_USER)}
                                </Select>
                            </Row>
                            {
                                type === CUSTOM &&
                                    <Row>
                                        <TextInput s={12} m={6} id="txtCustomSub" label="Custom Plan" icon="list"
                                                   value={customType} onChange={e => setCustomType(e.target.value)}/>
                                    </Row>
                            }
                        </Container>
                    </Modal>
                </Col>
                <Col className="mb-0 d-inline-flex align-items-center">
                    <CardPanel style={{padding: "12px", textAlign: "center", cursor: "pointer"}}
                               className="red darken-1 customer-reload-btn standard-btn"
                               onClick={() => {
                                setFilterActivityCustomers([]);
                                props.fetchAllCustomers();
                                props.searchUser('');
                                infoToast("Reloading customers")
                               }}>
                        <FontAwesomeIcon icon="sync" inverse/>&nbsp;&nbsp;<span className="text-primary"
                                                                                style={{fontSize: "1.1em"}}>Customers</span>
                    </CardPanel>
                </Col>
            </Row>
            <Row className="users-top-btn customer-top-header">
                <Col className="filter-allowed-card-section TotalDaysSection d-inline-flex align-items-center">
                    <p className="last30DaysSection m-0 ">Last 30 Days {isMobile && 'cards'}</p>
                    </Col>
                <Col className="filter-allowed-card-section cardCountSection">
                    <CardPanel style={{padding: "12px", textAlign: "center"}} className="standard-btn">
                        <FontAwesomeIcon icon={faVideo}/>&nbsp;&nbsp;
                        <span className="text-accent-1" style={{fontSize: "1.1em"}}>{!isMobile && 'Total Allowed Cards'} {isMobile && 'Allowed'}:{allowedCards}</span>
                    </CardPanel>
                    </Col>
                <Col className="filter-allowed-card-section cardCountSection">
                    <CardPanel style={{padding: "12px", textAlign: "center"}} className="standard-btn">
                        <FontAwesomeIcon icon={faVideo}/>&nbsp;&nbsp;
                        <span className="text-darken-1" style={{fontSize: "1.1em"}}>{!isMobile && 'Total Requested Cards'} {isMobile && 'Requested'}:{requestedCards}</span>
                    </CardPanel>
                </Col>
                <Col style={{marginTop:"16px"}} className="standard-btn spin-loader-cardCount-section">
                    {loading && <Spin /> }
                </Col>
            </Row>
            {
                props.auth.loggedInUser.client_type === ADMIN ? 
                <Row className="customers-filter-main-div customer-top-header">
                    {/* <Col className="customer-filter-dropdown-section"> */}
                    <Col className="d-inline-flex flex-column dropdownSectionWithLabel mb-0">
                        <label className="customer-Dropdown-label">Customer Type</label>
                        <CustomDropdown
                            className="customer-select-sub"
                            options={subscriptionOptions}
                            placeholder="Customer Type"
                            dropdownValue={filterData.subscriptionType}
                            handleOnchange={handleSubscriptionChange}
                        />
                    </Col>
                    <Col className="d-inline-flex flex-column dropdownSectionWithLabel mb-0">
                    <label className="customer-Dropdown-label">Referral</label>
                    <CustomDropdown
                    className="customer-select-sub"
                        options={referralOptions}
                        placeholder="Referral"
                        dropdownValue={filterData.referral}
                        handleOnchange={handleReferralChange}
                    />
                    </Col>
                    <Col className="d-inline-flex flex-column dropdownSectionWithLabel mb-0">
                    <label className="customer-Dropdown-label">Customer Activity</label>
                    <CustomDropdown
                        className="customer-select-sub"
                        options={activityOptions}
                        placeholder="Customer Activity"
                        dropdownValue={filterData.customerActivity}
                        handleOnchange={handleActivityChange}
                    />
                    </Col>
                    <Col className="response-rate-date-picker">
                    <CustomDatePicker
                    className="datePicker mobileMode"
                    setStartDate={(date) => {
                        setStartDate(date);
                        setfilterData(prev => ({
                        ...prev,
                        startDate: date
                        }));
                    }}
                    setEndDate={(date) => {
                        setEndDate(date);
                        setfilterData(prev => ({
                        ...prev,
                        endDate: date
                        }));
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    />
                    </Col>
                    <Col className="cutomer-filter-clear-btn mb-0 d-inline-flex align-items-center pt-20">
                        <Button className="standard-btn" onClick={()=>setfilterData(INITIAL_FILTER_STATE)}>Clear Filter{activeFilterCount() > 0 && `(+${activeFilterCount()})`}</Button>
                    </Col>
                    <Col className="customer-filter-export mb-0 d-inline-flex align-items-center pt-20">
                        <Button id="csv-link" className="standard-btn" onClick={() => exportCustomers()}>Export</Button>
                    </Col>
                    {/* </Col> */}
                </Row> : null
            }
            </div>
            <Row className={`customers-row-cards ${isMobile ? "customer-row-cards-MobileView" : ""}`}>
                {populateCustomers(props, confirmationOpen, setConfirmationOpen, onConfirmDelete, setOnConfirmDelete, filterData, filterActivityCustomers)}
            </Row>
            <Table id="customers-table">
                <thead>
                    <tr>
                        <th>FirstName</th>
                        <th>LastName</th>
                        <th>Email</th>
                        <th>Referral</th>
                        <th>OtherReferral</th>
                        <th>SocialMediaAccount</th>
                    </tr>
                </thead>
                <tbody>
                    {filterCsvData?.map((item, i) => <tr key={i+1 + "-row"}>
                        <td>{item.firstname}</td>
                        <td>{item.lastname}</td>
                        <td>{item.useremail}</td>
                        <td>{item.referral}</td>
                        <td>{item.otherreferral}</td>
                        <td>{item.social_media_link}</td>
                    </tr>)
                    }
                </tbody>
            </Table>
        </Container>
    );
});

function mapStateToProps({users, filter}) {
    return {users, filter};
}
